$doc-font-size: 12 !default;


/* headline scale */
$h-size-1: 1.3em !default;
$h-size-2: 1.15em !default;
$h-size-3: 1em !default;
$h-size-4: 1em !default;
$h-size-5: 1em !default;
$h-size-6: 1em !default;

// $masthead-link-color: #fff;
// $masthead-link-color-hover: #ccc;

$small: 600px !default;
$medium: 768px !default;
$medium-wide: 900px !default;
$large: 1024px !default;
$x-large: 1280px !default;
$max-width: $x-large !default;

@media (min-width: 48em) {
  #homepage {
    width: calc(100% - 360px);
  }
}
@media (max-width: 48em) {
  #homepage {
    padding: 0 1em;

  }
  .page__hero--overlay {
    padding: 2em 0 1em !important;
  }
}

a {
  text-decoration: none;
}
a:hover{
  text-decoration: underline;;
}

footer {
  display: flex !important;
  justify-content: center;
}

#homepage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;

  article {
    display: flex;
    flex-wrap: wrap;
  }
  .flex_content{
    flex: 6 1 500px;
    padding-right: 20px;
  }
  .flex_sidebar{
    flex: 1 0 220px;
  }
}

@media (min-width: 80em) {
  .page {
    padding-right: 0 !important;
  }
}

@media (min-width: 70em) {
  .page {
    float: none !important;
  }
}

@media (min-width: 64em) {
  .page {
    padding-right: 0 !important;
    float: none !important;
  }
}

#intro_form {
  display: flex;

  em {
    color: #777;
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1023px) {
  #intro_form {
    padding: 30px;
  }

  .half {
    width: 50%;
  }
  #sidebar_img {
    margin-right: 30px;
    text-align: right;
  }

}

@media only screen and (max-width: 1023px) {
 #intro_form {
    flex-direction: column;
    align-items: center;
  }
  .half {
    width: 100%;
  }
  #sidebar_img {
    padding-bottom: 30px;
  }
  #sidebar_img img {
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    max-height: 300px;
    vertical-align: middle;
  }
}

@media only screen and (min-width: 1023px) {
  #instafeed {
    overflow: auto;
    margin: 0 0 0 -1%;
    padding: 0;
  }
  #instafeed div {
    float: left;
    display: block;
    margin: 0 0 5px 5px;
    width: calc(50% - 5px);
    padding-bottom: calc(50% - 5px);
    position: relative;
  }
  #instafeed div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
}
@media only screen and (max-width: 1023px) {
  #instafeed {
    display: flex;
  }
  #instafeed div {
    float: left;
    display: block;
    margin: 0 0 5px 5px;
    width: calc(25% - 5px);
    padding-bottom: calc(25% - 5px);
    position: relative;
  }
  #instafeed div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
}
@media only screen and (min-width: 1023px) {
  .calendar {
    max-height: 400px;
    overflow-y: scroll;
  }
}

#announcement {
  margin-top: -2em;
  margin-bottom: 2em;
  display: block;
  a {
    color: lightgray;
    :visited {
      color: lightgray;
    }
  }
  span  {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0.3em;
    background-color: #f78a1c;
    color: white !important;
    text-align: center;
    font-weight: 600;
    font-size: 0.8em;
  }

}

#footer {
  .page__footer-copyright {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .icon{
    height: 1.5em;
    margin-right: 0.7em;
  }

  // for some reason, the discord icon is wildly different in size
  .fa-discord {
    font-size: 1.3em !important;
    position: relative;
    top: 2px;
  }

  .separator{
    margin: 0 0.7em;
    position: relative;
    top: -2px;
  }
  .dressme{
    position: relative;
    top: -2px;
  }
  i {
    font-size: 1.6em;
  }
}
.calendar {
  font-size: 0.7em;
  .event {
    display: flex;
    align-content: stretch;
  }
  .month {
    border: solid lightgray;
    padding: 2px 5px;
    border-width: 1px 0;
    background-color: whitesmoke;
    font-weight: 300;
  }
  .date {
    flex-shrink: 0;
    width: 1.8em;
    font-weight: 700;
    background-color: whitesmoke;
    text-align: center;
  }
  .item {
    padding: 0 5px;
    display: inline-block;
    width: 100%;
  }
  .event:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
  em {
    color: gray;
    font-size: 12px;
  }
}

.video-container {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow: hidden;
  justify-content: center;
}

@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
